<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <div>
            <div class="container-fluid p-0">
              <div class="row no-gutters">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                  <div
                    class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
                  >
                    <div class="w-100">
                      <div class="row justify-content-center">
                        <div class="col-lg-9">
                          <div>
                            <ValidationObserver tag="form" ref="form">
                              <div class="text-center">
                                <h4 class="font-size-18 mt-4">
                                  Welcome Back !
                                </h4>
                                <p class="text-muted">
                                  Sign in to continue to GMS.
                                </p>
                              </div>

                              <div class="p-2 mt-5">
                                <div
                                  class="form-group auth-form-group-custom mb-4"
                                >
                                  <label for="username">Email</label>
                                  <validation-provider
                                    rules="required"
                                    v-slot="{ errors }"
                                    name="Email"
                                  >
                                    <CInput
                                      placeholder="Email"
                                      autocomplete="username email"
                                      v-model="username"
                                    >
                                      <template #prepend-content
                                        ><CIcon name="cil-user"
                                      /></template>
                                    </CInput>
                                    <div class="errMsg">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </validation-provider>
                                </div>

                                <div
                                  class="form-group auth-form-group-custom mb-4"
                                >
                                  <i
                                    class="ri-lock-2-line auti-custom-input-icon"
                                  ></i>
                                  <label for="userpassword">Password</label>
                                  <validation-provider
                                    rules="required"
                                    v-slot="{ errors }"
                                    name="Password"
                                  >
                                    <CInput
                                      placeholder="Password"
                                      type="password"
                                      autocomplete="curent-password"
                                      v-model="password"
                                      @keydown.enter="login()"
                                    >
                                      <template #prepend-content
                                        ><CIcon name="cil-lock-locked"
                                      /></template>
                                    </CInput>
                                    <div class="errMsg">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </validation-provider>
                                </div>

                                <div class="mt-4 text-center">
                                  <button
                                    class="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    @keydown.enter="login()"
                                    @click.prevent="login()"
                                  >
                                    Log In
                                  </button>
                                </div>
                                <div class="mt-4 text-center">
                                  <div
                                    class="text-muted cur"
                                    style="cursor:pointer;"
                                    @click="forgetModel()"
                                  >
                                    Forgot your password?
                                  </div>
                                </div>
                              </div>
                            </ValidationObserver>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-1"></div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>

    <!-- 忘記密碼彈窗 -->
    <div class="popMask " v-if="forgotTip">
      <div class="pop_Modal ask_pop">
        <div class="popCon">
          <div class="close" @click="forgotTip = false">
            <img src="../../assets/img/x.png" />
          </div>
          <div class="popAll">
            <div class="title">忘記密碼</div>
            <CCard>
              <CCardBody>
                <ValidationObserver tag="form" ref="form1">
                  <CForm>
                    <validation-provider
                      rules="required|email"
                      v-slot="{ errors }"
                      name="Email"
                    >
                      <CInput
                        label="Email"
                        placeholder=""
                        class="col-sm-8"
                        v-model="mail"
                      />
                      <div class="errMsg">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <div class="form-group form-actions positionR">
              <div class="sendButton">
                <div class="button floatRight"></div>
                <div class="button floatRight">
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="forgot()"
                    >確認
                  </CButton>
                </div>

                <div class="clearBoth"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import Modal from '../Modal.vue'
export default {
  name: 'Login',
  data() {
    return {
      username: 'admin@dynacloud.co',
      password: 'password',
      mail: '',
      forgotTip: false,
    }
  },
  components: {
    Modal,
  },
  methods: {
    //   登入
    login() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          let data = {
            email: this.username,
            password: this.password,
          }
          this.$store.dispatch('actionLogin', data)
        } else {
          console.log('NO')
        }
      })
    },
    forgetModel() {
      console.log('forget model')
      this.forgotTip = true
      this.mail = ''
    },
    // 忘記密碼
    forgot() {
      this.$refs.form1.validate().then((valid) => {
        if (valid) {
          this.$store.dispatch('actionForgotPW', this.mail)
          this.mail = ''
          this.forgotTip = false
        } else {
          console.log('NO')
        }
      })
    },
  },
}
</script>
